<template>
       <div class="main">
        <div class="header-container">
            <div class="logo-container">
                <img src="./../assets/images/backbone-logo.png" alt="logo BACKBONE" />
            </div>
            <div class="logo-sep"></div>
            <div class="title-container">
                <h1>Webinar</h1>
            </div>
        </div>
        <div class="back-bar">
            <router-link to="/login" class="back-btn"></router-link>
        </div>
        <h1 class="text-subtitle">Password lost</h1>
        <h2>Enter your email address to reset your password</h2>
        <div class="sep-subtitle"></div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(resetPwd)" >
                <ValidationProvider  class="input-login"  rules="required|max:100|email" v-slot="{ errors }">
                    <label>Email address</label>
                    <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" />
                    <span class="notice-error">{{ errors[0] }}</span>
                </ValidationProvider>
                <input v-if="!loading" type="submit" class="btn-form" value="RESET" />
                <Loader v-else></Loader>
                <div class="form-failed" v-if="errorMsg" v-html="errorMsg"></div>
                <div class="form-succes" v-if="success">Please check your email to reset your password<br />If you haven't received the password reset message via email, check your spam.</div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../store'
import Loader from './../components/Loader'

export default {
    name :'PasswordLost',
    components : {
        ValidationProvider, ValidationObserver, Loader
    },
    data() {
        return {
            email : null,
            errorMsg : null,
            success : false,
            loading : false
        }
    },
    methods : {
        resetPwd() {
            this.loading = true
            this.errorMsg = null
            this.success = false

            let vm = this
            let params = {
                email : this.email
            }
            axios
                .post(store.getters.getURL + "user/passwordLost.php", params )
                .then(function (response) { 
                    vm.loading = false
                    if(response.data.state == 'success') {
                        vm.success = true
                    }else {
                        vm.errorMsg = response.data.retour
                    }
                })
                .catch((err) => {
                    this.errorMsg = "An error has occured: " +  err
                });

        }
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        font-size:18px;
        font-family: $font;
    }
</style>